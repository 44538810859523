import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, FormlyModule]
})
export class FormComponent {
  state: boolean;
  model;

  constructor(public bsModalRef: BsModalRef) {}

  submit(model) {
    if (this.bsModalRef.content.formConfig.form.valid) {
      this.state = true;
      this.model = model;
      this.bsModalRef.hide();
    }
  }

  cancel() {
    this.state = false;
    this.bsModalRef.hide();
  }

  alternativeAction() {
    if (this.bsModalRef.content && this.bsModalRef.content.action_alternative) {
      this.bsModalRef.content.action_alternative();
    }
    this.bsModalRef.hide();
  }
}
