import { Component } from '@angular/core';
import { NgIf } from '@angular/common';
import { UntypedFormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  standalone: true,
  imports: [NgIf, ReactiveFormsModule]
})
export class ConfirmationComponent {
  state: boolean;

  checkboxForm = new UntypedFormGroup({
    checkbox: new UntypedFormControl()
  });

  constructor(public bsModalRef: BsModalRef) {}

  confirm() {
    this.state = true;
    if (this.bsModalRef.content.model) {
      const checkboxValue = this.checkboxForm.controls.checkbox.value;
      this.bsModalRef.content.model.checkbox = checkboxValue === null ? this.bsModalRef.content.isChecked : checkboxValue;
      this.bsModalRef.content.model.checkbox = checkboxValue;
    }
    this.bsModalRef.hide();
  }

  cancel() {
    this.state = false;
    this.bsModalRef.hide();
  }
}
