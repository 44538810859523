<!-- Header -->
<div class="modal-header">

  <!-- Title -->
  <h6 class="modal-title">{{ bsModalRef.content?.title }}</h6>
  <!-- Title end -->

  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<!-- Header end -->

<!-- Content -->
<div class="modal-body">
  <div [innerHTML]="bsModalRef.content?.description"></div>
</div>
<!-- Content end -->

<!-- Footer -->
<div class="modal-footer">
  <button type="button" class="btn btn-xs btn-secondary" (click)="bsModalRef.hide()" i18n="@@common.close">Close</button>
</div>
<!-- Footer end -->
