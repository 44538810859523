<!-- Header -->
<div class="modal-header">

  <!-- Title -->
  <h6 class="modal-title">{{ bsModalRef.content?.title }}</h6>
  <!-- Title end -->

  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
    &times;
  </button>
</div>
<!-- Header end -->

<!-- Content -->
<div class="modal-body">
  <div [innerHTML]="bsModalRef.content?.description"></div>
  <form [formGroup]="bsModalRef.content?.formConfig.form"
        (ngSubmit)="submit(bsModalRef.content?.formConfig.model)"
        *ngIf="bsModalRef.content"
        style="margin-top: 20px"
        appPreventEnter>
    <formly-form [form]="bsModalRef.content?.formConfig.form"
                 [fields]="bsModalRef.content?.formConfig.fields"
                 [model]="bsModalRef.content?.formConfig.model"
                 *ngIf="bsModalRef.content?.formConfig.fields">
    </formly-form>

    <!-- Footer -->
    <div>
      <div>
        <div *ngIf="bsModalRef.content?.button_alternative as button_alternative" class="col text-start">
          <button type="button"
                  class="btn btn-sm btn-dark"
                  (click)="alternativeAction()">
            {{ button_alternative }}
          </button>
        </div>
        <div style="display: flex; justify-content: flex-end; width: 100%; margin-top: 10px;">
          <button type="button"
                  class="button-secondary-outlined"
                  style="margin-right: 8px;"
                  (click)="cancel()"
          ><ng-container *ngIf="bsModalRef.content?.button_cancel; else cancelTpl">{{ bsModalRef.content?.button_cancel }}</ng-container><ng-template i18n="@@common.cancel" #cancelTpl>Cancel</ng-template></button>
          <button type="submit"
                  class="button-secondary">
            <span><ng-container *ngIf="bsModalRef.content?.button_confirm; else confirmTpl">{{ bsModalRef.content?.button_confirm }}</ng-container><ng-template i18n="@@common.submit" #confirmTpl>Submit</ng-template></span>
          </button>
        </div>
      </div>
    </div>
    <!-- Footer end -->

  </form>
</div>
<!-- Content end -->
