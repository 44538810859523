/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, Renderer2, RendererFactory2, StaticProvider } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { BasicForm } from '../forms/basic.form';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { FormComponent } from './form/form.component';
import { InfoComponent } from './info/info.component';

export interface ModalConfig {
  id?: number;
  backdrop?: boolean | 'static';
  keyboard?: boolean;
  focus?: boolean;
  show?: boolean;
  ignoreBackdropClick?: boolean;
  animated?: boolean;
  providers?: StaticProvider[];
  ariaLabelledBy?: string;
  ariaDescribedby?: string;

  title?: string;
  description?: string;
  button_confirm?: string;
  button_cancel?: string;
  button_alternative?: string;
  class?: string;
  state?: boolean;
  model?: any;
  formConfig?: BasicForm;
  emitCancelEvent?: boolean;
  data?: any;
  action_alternative?: () => void;
  tenant_id?: any;
  trial?: boolean;
  hasCheckbox?: boolean;
  isChecked?: boolean;
  checkboxLabel?: string;
  initialState?: Partial<ModalConfig>;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  bsModalRef: BsModalRef;
  renderer: Renderer2;
  removeKeyListener: () => void;

  constructor(
    private bsModalService: BsModalService,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  confirmation(config?: ModalConfig) {
    const $response = new Subject();
    let modal: BsModalRef = undefined;

    const modalShown = this.bsModalService.onShown.subscribe(() => {
      this.removeKeyListener = this.renderer.listen(
        'document',
        'keydown.enter',
        (event: KeyboardEvent) => {
          event.preventDefault();
          event.stopPropagation();
          modal.hide();
          modal.content.state = true;
        }
      );
    });

    modal = this.bsModalService.show(ConfirmationComponent, {
      animated: true,
      backdrop: config.backdrop || 'static',
      class: config.class || 'modal-sm',
      ignoreBackdropClick: config.ignoreBackdropClick === false ? false : true,
      initialState: config.initialState,
      keyboard: config.keyboard === false ? false : true,
      show: config.show || false,
    });

    const modalHidden = this.bsModalService.onHidden.subscribe(() => {
      if (modal.content.state) {
        $response.next(modal.content.state);
      } else if (config.initialState.emitCancelEvent) {
        $response.next(false);
      }

      this.destroyAndUnsubscribe($response, modalShown, modalHidden);
    });

    return $response.asObservable();
  }

  form(config: ModalConfig) {
    const $response = new Subject();
    let modal: BsModalRef = undefined;
    const modalCount = this.bsModalService.getModalsCount();

    modal = this.bsModalService.show(FormComponent, {
      animated: true,
      backdrop: config.backdrop || 'static',
      class: config.class || 'modal-lg',
      ignoreBackdropClick: config.ignoreBackdropClick === false ? false : true,
      initialState: config.initialState,
      keyboard: config.keyboard === false ? false : true,
      show: config.show || false,
    });

    const modalHidden = this.bsModalService.onHidden.subscribe(() => {
      if (modalCount === this.bsModalService.getModalsCount()) {
        if (modal.content.state) {
          $response.next(modal.content.model);
        } else if (config.initialState.emitCancelEvent) {
          $response.next(false);
        }

        this.destroyAndUnsubscribe($response, undefined, modalHidden);
      }
    });

    return $response.asObservable();
  }

  info(config: ModalConfig) {
    const $response = new Subject();
    let modal: BsModalRef = undefined;

    const modalShown = this.bsModalService.onShown.subscribe(() => {
      this.removeKeyListener = this.renderer.listen(
        'document',
        'keydown.enter',
        (event: KeyboardEvent) => {
          event.preventDefault();
          event.stopPropagation();
          modal.hide();
        }
      );
    });

    modal = this.bsModalService.show(InfoComponent, {
      animated: true,
      backdrop: config.backdrop || 'static',
      class: config.initialState.class || 'modal-lg',
      ignoreBackdropClick: config.ignoreBackdropClick === false ? false : true,
      initialState: config.initialState,
      keyboard: config.keyboard === false ? false : true,
      show: config.show || false,
    });

    const modalHidden = this.bsModalService.onHidden.subscribe(() => {
      this.destroyAndUnsubscribe($response, modalShown, modalHidden);
    });

    return $response.asObservable();
  }

  customized(config: ModalConfig, component) {
    const $response = new Subject<any>();
    let modal: BsModalRef = undefined;
    const modalCount = this.bsModalService.getModalsCount();

    modal = this.bsModalService.show(component, {
      animated: true,
      backdrop: config.backdrop || 'static',
      class: config.initialState.class || 'modal-lg',
      ignoreBackdropClick: config.ignoreBackdropClick === false ? false : true,
      initialState: config.initialState,
      keyboard: config.keyboard === false ? false : true,
      show: config.show || false,
    });

    const modalHidden = this.bsModalService.onHidden.subscribe(() => {
      if (modalCount === this.bsModalService.getModalsCount()) {
        if (modal.content.state) {
          $response.next(modal.content.model);
        } else if (config.initialState.emitCancelEvent) {
          $response.next(false);
        }

        this.destroyAndUnsubscribe($response, undefined, modalHidden);
      }
    });

    return $response.asObservable();
  }

  destroyAndUnsubscribe(
    $response: Subject<any>,
    modalShown: Subscription,
    modalHidden: Subscription
  ) {
    /* eslint-disable @typescript-eslint/no-unused-expressions */
    this.removeKeyListener && this.removeKeyListener();
    $response && $response.complete();
    modalShown && modalShown.unsubscribe();
    modalHidden && modalHidden.unsubscribe();
    /* eslint-enable */
  }

  hide() {
    this.bsModalService.hide(1);
  }
}
