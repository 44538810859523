<form [formGroup]="checkboxForm" *ngIf="bsModalRef.content" novalidate>
  <!-- Header -->
  <div class="modal-header">

    <!-- Title -->
    <h6 class="modal-title">{{ bsModalRef.content?.title }}</h6>
    <!-- Title end -->

    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
      &times;
    </button>
  </div>
  <!-- Header end -->

  <!-- Content -->
  <div class="modal-body" [innerHTML]="bsModalRef.content?.description"></div>
  <!-- Content end -->

  <!-- Footer -->
  <div class="modal-footer">
    <div class="form-group d-flex w-100 mb-0" *ngIf="bsModalRef.content?.hasCheckbox">
      <input formControlName="checkbox" type="checkbox" id="checkbox_id" class="me-1" [checked]="bsModalRef.content?.isChecked || false">
      <label class="me-0 form-control-label control-label" *ngIf="bsModalRef.content?.checkboxLabel" for="checkbox_id">{{ bsModalRef.content?.checkboxLabel }}</label>
    </div>
    <button type="button" class="button-secondary-outlined" style="margin-right: 8px" (click)="cancel()"><ng-container *ngIf="bsModalRef.content?.button_cancel; else cancelTpl">{{ bsModalRef.content?.button_cancel }}</ng-container><ng-template i18n="@@shared.components.modals.confirmation.btn.cancel" #cancelTpl>Cancel</ng-template></button>
    <button type="button" class="button-secondary" (click)="confirm()"><span><ng-container *ngIf="bsModalRef.content?.button_confirm; else confirmTpl">{{ bsModalRef.content?.button_confirm }}</ng-container><ng-template i18n="@@shared.components.modals.confirmation.btn.confirm" #confirmTpl>Confirm</ng-template></span></button>
  </div>
  <!-- Footer end -->
</form>
