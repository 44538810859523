import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  standalone: true
})
export class InfoComponent {
  state: boolean;

  constructor(public bsModalRef: BsModalRef, protected sanitizer: DomSanitizer) {}

  getText(value: string) {
    return value;
  }

  cancel() {
    this.bsModalRef.hide();
  }
}
